import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not Found" />
      <title>Not found</title>

      <section>
        <div className="container">
          <h1>Page not found</h1>
          <Link to="/">Go home</Link>.
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
